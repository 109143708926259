@tailwind base;
@tailwind components;
@tailwind utilities;

.gutter {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  /*opacity: 30%;*/
  border-top: 2px solid theme('colors.gray.800');
}

.gutter.gutter-vertical {
  position: relative;
}

.gutter.gutter-vertical:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 10px;
  opacity: 30%;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

